<template>
  <div>
    <v-row>
      <v-col xl="6" lg="6" md="6" sm="12" cols="12">
        <v-dialog ref="dialog" v-model="modal" :return-value.sync="filter_date_range" persistent width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="filter_date_range_text"
              :label="$t('general.date_range')"
              :prepend-icon="calendar_icon"
              readonly
              hide-details
              v-bind="attrs"
              outlined
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="filter_date_range" scrollable range>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="removeDateFilter"> Cancel </v-btn>
            <v-btn text color="primary" @click="closeDialog()"> OK </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <CorrectDataTable
          :table-headers="payment_record_header"
          :table-data="payment_record_list"
          :page="tablePage"
          :page-limit="tablePageLimit"
          :page-limit-options="tablePageLimitOptions"
          :item-total="total_items"
          :is-loading="load_table"
          :enable-search="false"
          :enable-filter="false"
          :enable-date-range="false"
          :enable-clear-all="false"
          :disable-pagination="load_table"
          :disable-sort="true"
          @options-update="onTableChange"
        ></CorrectDataTable>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CorrectDataTable from '@/components/CorrectDataTable.vue'
import { getData } from '@/api'
import { mdiCalendar } from '@mdi/js'
export default {
  name: 'PaymentRecord',
  components: {
    CorrectDataTable,
  },
  data: () => ({
    calendar_icon: mdiCalendar,
    payment_record_header: [],
    payment_record_list: [],
    load_table: true,
    tablePage: 1,
    tablePageLimit: 10,
    tablePageLimitOptions: [10, 20, 50],
    total_items: 0,
    modal: false,
    filter_date_range: [],
    filter_date_range_text: '',
  }),
  methods: {
    initializeHeader() {
      this.payment_record_header = [
        {
          text: `${this.$t('job_post.agreement')} ${this.$t('job_post.create_date')}`,
          value: 'create_date',
        },
        {
          text: this.$t('job_post.job_id'),
          value: 'job_id',
        },
        {
          text: this.$t('customer.customer_id'),
          value: 'customer_id',
        },
        {
          text: this.$t('job_post.customer_first_name'),
          value: 'customer_first_name',
        },
        {
          text: this.$t('job_post.customer_last_name'),
          value: 'customer_last_name',
        },
        {
          text: this.$t('job_post.payment_method'),
          value: 'payment_method',
        },
        {
          text: this.$t('job_post.payment_status'),
          value: 'status',
        },
        {
          text: '',
          value: 'viewAgreementButton',
        },
      ]
    },
    async initializeData() {
      this.load_table = true
      try {
        let send_data = {}
        //, filter_status: 'completed'
        if (this.filter_date_range.length != 0) {
          // TODO:
        }
        let params = new URLSearchParams()
        params.set('cms_get_agreement', JSON.stringify(send_data))
        let get_agreement_data = await getData(params)
        this.$func.log('-----Get Agreement Data-----')
        this.$func.log(get_agreement_data)

        if (!Array.isArray(get_agreement_data)) {
          let agreement = get_agreement_data.data

          let customer_id_list = agreement.map(obj => {
            return obj.user_id
          })

          this.$func.log(customer_id_list)

          let agreement_id_list = agreement.map(obj => {
            return obj.id
          })

          this.$func.log(agreement_id_list)

          params = new URLSearchParams()
          params.set('cms_get_user', JSON.stringify({ user_id_list: customer_id_list }))
          let get_user_data = await getData(params)
          this.$func.log('-----Get User Data-----')
          this.$func.log(get_user_data)
          let user = get_user_data.data

          params = new URLSearchParams()
          params.set(
            'cms_get_payment',
            JSON.stringify({
              agreement_id_list: agreement_id_list,
              page: this.tablePage - 1,
              limit: this.tablePageLimit,
            }),
          )
          let get_payment_data = await getData(params)
          this.$func.log('-----Get Payment Data-----')
          this.$func.log(get_payment_data)
          let payment = get_payment_data.data
          this.total_items = get_payment_data.total

          let temp = []
          for (let i = 0; i < agreement.length; i++) {
            let temp_data = {
              create_date: agreement[i].create_date,
              job_id: agreement[i].job_id,
              customer_id: agreement[i].user_id,
            }

            let user_data = user.filter(obj => {
              return obj.id == agreement[i].user_id
            })

            if (user_data.length > 0) {
              temp_data.customer_first_name = user_data[0].first_name
              temp_data.customer_last_name = user_data[0].last_name
            }

            let payment_data = payment.filter(obj => {
              return obj.agreement_id == agreement[i].id
            })

            if (payment_data.length > 0) {
              temp_data.payment_method = payment_data[0].payment_method
              temp_data.status = payment_data[0].status
            } else {
              continue
            }

            temp.push(temp_data)
          }

          this.payment_record_list = temp
        }
        this.load_table = false
      } catch (error) {
        this.$func.log(error)
        this.load_table = false
      }
    },
    removeDateFilter() {
      this.modal = false
      this.filter_date_range = []
      this.filter_date_range_text = ''
      this.$refs.dialog.save(this.filter_date_range)

      //this.tablePage = 0
      //this.initializeData()
    },
    closeDialog() {
      if (this.filter_date_range.length == 1) {
        this.filter_date_range.push(this.filter_date_range[0])
      } else if (this.filter_date_range.length == 2) {
        if (this.filter_date_range[0] > this.filter_date_range[1]) {
          let temp = this.filter_date_range[0]
          this.filter_date_range[0] = this.filter_date_range[1]
          this.filter_date_range[1] = temp
        }
      }

      this.filter_date_range_text = this.filter_date_range.join(' ~ ')
      this.$refs.dialog.save(this.filter_date_range)
      //this.tablePage = 0
      //this.initializeData()
    },
    onTableChange(options) {
      if (options.itemsPerPage !== this.tablePageLimit) {
        this.tablePage = 1 // reset to first page if view options changed
      } else {
        this.tablePage = options.page
      }
      if (options.sortBy.length > 0) {
        if (options.sortBy[0].indexOf('user_data.') === 0) {
          this.tableSortKey = options.sortBy[0].substring(10)
        } else {
          this.tableSortKey = options.sortBy[0]
        }
      }

      this.tablePageLimit = options.itemsPerPage

      if (this.filter_date_range.length != 0) {
        this.initializeData(this.filter_date_range)
      } else {
        this.initializeData()
      }
    },
  },
  async created() {
    const fetchAllow = await this.checkToken()
    if (fetchAllow) {
      this.initializeHeader()
      this.initializeData()
    }
  },
}
</script>